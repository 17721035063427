var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionCard',{attrs:{"label":"Информация о заявке"}},[_c('VRow',_vm._l((_vm.information),function(ref){
var label = ref.label;
var value = ref.value;
var options = ref.options;
var hint = ref.hint;
return _c('VCol',{key:label,attrs:{"cols":"12","md":"6"}},[_c('StaticField',{attrs:{"label":label,"value":value,"items":options}}),(!!hint)?_c('small',{staticClass:"error--text",domProps:{"textContent":_vm._s(hint)}}):_vm._e()],1)}),1),_c('VRow',_vm._l((_vm.persons),function(ref){
var label = ref.label;
var value = ref.value;
var options = ref.options;
return _c('VCol',{key:label,attrs:{"cols":"12","md":"3"}},[_c('StaticField',{attrs:{"label":label,"value":value,"items":options}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }