import { render, staticRenderFns } from "./InspectionFeedback.vue?vue&type=template&id=711cb553&"
import script from "./InspectionFeedback.vue?vue&type=script&lang=js&"
export * from "./InspectionFeedback.vue?vue&type=script&lang=js&"
import style0 from "./InspectionFeedback.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VRating})
